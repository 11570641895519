import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import {
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  TryIrisGPTForFreeBanner,
  ZuoraSection,
  Faq
} from "../commonComponents"


import { Layout } from "../components/Layout"


export default function Zuora() {
  return (
    <Layout
      title="Zuora + IrisAgent: Case Study in Support Automation"
      keywords="Zuora, IrisAgent customers, Zuora AI, Zuora support"
      description="Zuora leverages IrisAgent to revolutionize customer support, streamlining processes and enhancing satisfaction. Discover how our partnership with Zuora transforms service delivery in our in-depth case study."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/zuora/"
        />
      </Helmet>
      <main id="main">
        {/*  ======= Hero Section =======  */}
        <section
        id="hero-new-design"

      >
        <div className="container w-100 mw-100 m-0" style={{backgroundImage: "url(/img/zuora-bg.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
            <div className={"row"}>
                <div
                    className={"col-lg-12 col-md-12 col-sm-24 col-xs-24 section-padding-left section-padding-right "}
                    style={{ paddingLeft: "0"}}
                >
                <br/>
                    <h1 className="title-support-new-design" style={{fontWeight: 500, textAlign: "left"}}>
                        <span className="text-highlight">
            Zuora </span>solves customer issues <span className="text-highlight">10x faster</span> with IrisAgent AI
                    </h1>
                    <div className="description-support-new-design w-100" style={{textAlign: "left"}}>
                        Zuora transforms its support experience with automated triaging, intelligent routing, and self-service resolutions using IrisAgent AI Support Automation
                    </div>

                <div style={{ paddingTop: "6%", paddingBottom: "6%" ,textAlign:"center", display:"flex", gap:"20px", justifyContent:"center"}}>
                <img
                        src="/img/zuora-bg-logo.png"
                        alt="zuora logo"
                        style={{ width: "92%"}}
                      />
                 </div>

                </div>
            </div>
        </div>
      </section>
        {/*  End Hero  */}
        {/* <!-- ======= Counts Section ======= --> */}
        <ZuoraSection theme="dark" />
        {/* <!-- End Counts Section --> */}


        <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin:"10%" }}>
            <h2><span style={{ color: "var(--text-highlight-color)", fontWeight: 600 }}>The Challenge</span></h2>
            <br/>
            Since its inception in 2007, Zuora has grown to be a publicly traded company with over 1000 customers. It has also evolved to a multi-product suite that enables automated pricing and packaging, recurring billing, payments and revenue accounting, and subscription metrics solutions. With the growth and introduction of multiple product lines, support operations have evolved into complex operations involving multiple teams, workflows, and systems that must work in tandem.  Fast forward to 2024, Zuora realized this was creating challenges, namely,<br/><br/>
            <ul>
                <li><b>Multiple customer touchpoints</b> - Zuora customers accessed the self-service support portal, trust portal, and support via email and phone, but with no clear path to subject matter experts (SMEs) for resolution.</li><br/>
                <li><b>Efficiency</b> - First Call Resolution and First Day Resolution rates were capped due to the several steps involved in getting a resolution.</li><br/>
                <li><b>Effectiveness</b> - The mostly manual triage process and workflows impacted the ability to serve the customer requests effectively</li>
            </ul><br/>

            <img
                src="/img/Zuora_before_AI.png"
                alt="zuora"
                style={{ width: "90%", marginBottom:"10%" }}
              />

            <br/>

            <h2><span style={{ color: "var(--text-highlight-color)", fontWeight: 600 }}>The Solution: AI-powered Support Automation</span></h2>
            <br/>
            It was clear that Zuora needed to transform the support experience - deliver faster and more accurate responses to customers. They evaluated IrisAgent AI-powered Support Automation to help automate tagging and intent detection, streamline workflows, and bring the context and intelligence from engineering teams to the support teams that used Zendesk CRM.<br/><br/>


            Zuora implemented the following capabilities,<br/><br/>
            <ul>
            <li><b>IrisGPT chatbot and search</b> for self-service</li>
            <li><b>Automated tagging</b> and intent detection of issues in Zendesk CRM</li>
            <li><b>Automated triaging and routing</b> to improve response times and resolution</li>
            <li><b>Agent Assist</b> within Zendesk to help support representatives improve FCR</li>
             </ul>
            <br/><br/>
            <img
                src="/img/Zuora_AI.png"
                alt="zuora"
                style={{ width: "90%", marginBottom:"10%" }}
              />
            <br/>

            <h2><span style={{ color: "var(--text-highlight-color)", fontWeight: 600 }}>The Results</span></h2>
            <br/>
            <table>
                <tr>
                    <th>Before</th>
                    <th>With IrisAgent AI Support Automation</th>
                </tr>
                <tr>
                    <td>⤬ Self-service portal with keyword-based KB article search</td>
                    <td>✔ IrisGPT powered LLM chatbot and intelligent search</td>
                </tr>
                <tr>
                    <td>⤬ Manual tagging and triaging</td>
                    <td>✔ Automated tagging<br/>
                        ✔ Automated intent detection<br/>
                        ✔ Automated triaging<br/>
                        ✔ Intelligent Routing to SMEs</td>
                </tr>
                <tr>
                    <td>⤬ KB article searches, tribal knowledge</td>
                    <td>✔ Intelligent real-time Agent Assist</td>
                </tr>
                <tr>
                    <td>⤬ Information silos between engineering and support teams</td>
                    <td>✔ Agent Assist with real-time information on       product releases, issues from JIRA </td>
                </tr>
                <tr>
                    <td>⤬ Limited insights </td>
                    <td>✔ Ticket sentiment and insights<br/>
                        ✔ Customer insights<br/>
                        ✔ Product Quality insights<br/>
                      </td>
                </tr>
            </table>
            <br/><br/>
            Since deploying IrisAgent AI Support Automation, Zuora has seen,
            <br/><br/>
            <ul>
                <li><b>30%</b> of queries addressed by AI Agent Assist, enabling the Agents to focus on real impactful issues</li>
                <li><b>10x</b> improvement in Mean Time to Resolution in many scenarios</li>
                <li><b>40%</b> reduction in escalations</li>
                <li>Real-time product quality insights and improved CSAT</li>
            </ul>
            </div>
          </div>
        </section>

      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}





        {/* <!-- ======= Contact Services Section ======= --> */}
        {/* <ContactSection /> */}
        {/* <!-- End Contact Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}
